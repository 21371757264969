import React from 'react';
import { SectionHeader } from '../../../modules/common/components/section-header';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { Link } from 'gatsby';

import { StaticImage } from 'gatsby-plugin-image';

import '../../generic-page.scss';
import { Row, Col } from 'react-bootstrap';
import { YoutubeEmbed } from '../../../modules/common/components/youtube-embed';
import { CategoryCard } from '../../../modules/cs/main/components/category-card';

const HSRGuidesFHPage: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page'} game="hsr">
      <ul className="breadcrumb">
        <li>
          <Link to="/star-rail/">Honkai: Star Rail</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/star-rail/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Forgotten Hall</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/starrail/categories/category_fhm.png"
            alt="Forgotten Hall"
          />
        </div>
        <div className="page-details">
          <h1>Forgotten Hall</h1>
          <h2>
            A guide for the Forgotten Hall Memory mode in Honkai: Star Rail.
          </h2>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297256"></div>
      <div className="page-content">
        <SectionHeader title="Forgotten Hall" />
        <p>
          Forgotten Hall is a game mode where the objective is to defeat a group
          of enemies in as few turns as possible. After completing a Forgotten
          Hall stage you will be awarded between 0 to 3 stars for that stage
          depending on how many turns it took you to defeat the enemies in that
          stage. The more stars that you are awarded, the better the rewards you
          receive.
        </p>
        <p>
          It is a most challenging game mode with the final stages being
          considered the currently available end game content. Also those who
          played Genshin Impact should be very familiar with this mode as it is
          the equivalent of Abyss from GI.
        </p>
        <p>
          Forgotten Hall is divided in two parts: Memory and Memory of Chaos.
        </p>
        <ul>
          <li>
            Memory consists of 15 stages and provides a one time reward of
            Stellar Jades upon completion.
          </li>
          <ul>
            <li>
              The later stages of Memory require the use of two teams of up to 4
              Characters.
            </li>
          </ul>
          <li>
            Memory of Chaos consists of 10 stages and reward Stellar Jades upon
            completion
          </li>
          <ul>
            <li>
              Memory of Chaos stages are more challenging than Memory stages.
            </li>
            <li>
              Memory of Chaos stages reset periodically, allowing the player to
              complete them again for additional Stellar Jades.
            </li>
          </ul>
        </ul>
        <h5>Memory Turbulence</h5>
        <p>
          The Memory Turbulence is a special effect that affects the enemies or
          your characters during the battle. Each stage has a different Memory
          and often you need to build the team around it (and the enemy) to pass
          the stage.
        </p>
        <p>Here's a video showing the mode in action:</p>
        <Row className="video-row">
          <Col xs={12} lg={6}>
            <YoutubeEmbed embedId="ZvvGSK01_uc" />
          </Col>
        </Row>
        <SectionHeader title="Forgotten Hall - Memory of Chaos" />
        <p>
          To learn more about the first season of Memory of Chaos check our
          other guide:
        </p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Memory of Chaos"
            link="/star-rail/guides/memory-of-chaos"
            image={
              <StaticImage
                src="../../../images/starrail/categories/category_fh.png"
                alt="Memory of Chaos"
              />
            }
            isNew
          />
        </Row>
        <div className="fuse-ad-placeholder" data-fuse="22844297256"></div>
      </div>
    </DashboardLayout>
  );
};

export default HSRGuidesFHPage;

export const Head: React.FC = () => (
  <Seo
    title="Forgotten Hall | Honkai: Star Rail | Prydwen Institute"
    description="A guide for the Forgotten Hall Memory mode in Honkai: Star Rail."
  />
);
